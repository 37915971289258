import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Logo from '../assets/img/logo.svg'
import { Layout, Subline, Article, Wrapper, Button, SectionTitle, SubPageHeader } from '../components'

const Content = styled.div`
  grid-column: 2;
  position: relative;
  z-index: 9000;
  margin-top: -3rem;
  padding: 2rem 4rem;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  background-color: ${props => props.theme.colors.white};

  .blog-title {
    margin-bottom: 1.5rem;
    text-align: center;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 1rem 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 1rem;
  }
  overflow: hidden;
`

const Hero = styled.div`
  grid-column: 2;
  padding: 1rem;
  text-align: center;

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 0.5rem;
  }

  p {
    font-size: 1.68rem;
    margin-top: -1rem;
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: 1.45rem;
    }
    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      font-size: 1.25rem;
    }
  }
`

const BlogPage = ({
  data: {
    allMdx: { edges: postEdges },
  },
}) => (
  <Layout className="blog">
    <Wrapper>
      <SubPageHeader />
      <Content>
        <Subline className="blog-title">&mdash; BLOG &mdash;</Subline>
        {postEdges.map(post => (
          <Article
            title={post.node.frontmatter.title}
            date={post.node.frontmatter.date}
            excerpt={post.node.excerpt}
            timeToRead={post.node.timeToRead}
            slug={post.node.fields.slug}
            categories={post.node.frontmatter.categories}
            image={post.node.frontmatter.image}
            imageCaption={post.node.frontmatter.imageCaption}
            video={post.node.frontmatter.video}
            key={post.node.fields.slug}
          />
        ))}
      </Content>
    </Wrapper>
  </Layout>
)

export default BlogPage

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const BlogQuery = graphql`
  query BlogQuery {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MM/DD/YYYY")
            categories
            image
            imageCaption
            video
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`
